import axios from 'axios'

const BASE_API_URL = process.env.REACT_APP_BACKEND_URI + '/api/plants/'
const PHAENO_APP_API_KEY = process.env.REACT_APP_PHAENO_APP_API_KEY
const getStationAvailablePlants = async (stationId) => {
    let data
    try {
        await axios.get(BASE_API_URL + 'byStation/', {
          params: { _id: stationId }, 
          headers: {
            'x-api-key': PHAENO_APP_API_KEY
          },
        }).then(function (response) {
          data = response.data
        })
    }
    catch (error) {
        return []
    }
    if (data.hasOwnProperty('stack')) return []
    return data
}

const getNrgAvailablePlants = async (nrgId) => {
    let data
    try {
        await axios.get(BASE_API_URL + 'byNrg/', {
          params: { nrg_id: nrgId },
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          },
        })
            .then(function (response) {
                data = response.data
            })
    }
    catch (error) {
        return []
    }
    if (data.hasOwnProperty('stack')) return []
    return data
}

const getAllAvailablePlants = async () => {
    let data
    try {
        await axios.get(BASE_API_URL, {
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          },
        })
        .then(function (response) {
            data = response.data
        })
    }
    catch (error) {
        return []
    }
    if (data.hasOwnProperty('stack')) return []
    return data
}

const plantService = {
    getStationAvailablePlants,
    getNrgAvailablePlants,
    getAllAvailablePlants,
}

export default plantService