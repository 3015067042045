import * as React from 'react';
import Paper from '@mui/material/Paper';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import _ from "lodash"

export default function BasicTable(props) {
    return (

        <TableContainer component={Paper} sx={{ visibility: `${(props.isMapOpen) ? 'hidden' : 'visible'}` }}>
            <Table stickyHeader sx={{ minWidth: 240 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ backgroundColor:"lightblue" }} >#</TableCell>
                        <TableCell sx={{ backgroundColor:"lightblue" }} align="right">Phase</TableCell>
                        <TableCell sx={{ backgroundColor:"lightblue" }} align="right">Datum</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (!_.isEmpty(props.observations))
                            ?
                                Object.entries(props.observations).map(([key, value]) => (
                                <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {Number(key) + 1}
                                    </TableCell>
                                    <TableCell align="right">{value.phase_id.name}</TableCell>
                                    <TableCell align="right">{new Date(value.metadata.date).getDate() + "." + (new Date(value.metadata.date).getMonth() + 1) + "." + new Date(value.metadata.date).getFullYear()}</TableCell>
                                </TableRow>
                            )
                        )
                        :
                        <TableRow></TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}