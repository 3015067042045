import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "mapbox-gl";
import stationService from '../../services/stationService/stationService'
import eflService from '../../services/eflService/eflService'
import _ from 'lodash'
import closeMapboxIcon from "../../icons/close_mapbox_icon.png";

mapboxgl.accessToken = 'pk.eyJ1IjoiZGVoYXJkZSIsImEiOiJjbGIwaTZxN2MwenhnM3BvM2V0MG0wa2ViIn0.yleVelEUj-3AmaYI0Rs8lg';

export default function MapContainer(props) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [hasMapFinishedLoading, setMapFinishedLoading] = useState(false)
    const [lng, setLng] = useState(13.404954);
    const [lat, setLat] = useState(52.520008);
    const [zoom, setZoom] = useState(9);
    const nrgCenterpoints = {
        74: [[12.970015515900883, 52.78617150078], [14.530079481966368, 53.59297976435917]],
        75: [[12.296596871505074, 52.463229729921494], [14.396113936730956, 53.50475148530981]],
        77: [[11.144891795120259, 52.37983989548303], [13.576366335002973, 53.59402556892229]],
        78: [[11.777809757715346, 52.236302435052295], [13.73039968893508, 53.222778984144355]],
        79: [[12.920697874696458, 52.09152340990906], [14.799218618265684, 53.0328909556724]],
        80: [[12.870502172603636, 52.135642311272676], [15.456976017841697, 53.42748637093487]],
        81: [[11.90733472379739, 51.602784920333306], [14.28434506357624, 52.81322176018526]],
        82: [[12.993328780255041, 51.49040891880435], [15.255333790149109, 52.65759395871092]],
        83: [[13.492152717404707, 51.5521388419333], [14.7928122411858, 52.215647797160756]],
        84: [[13.019780284308212, 51.151580159489015], [14.952876143172887, 52.155690206920525]],
        85: [[11.865912427283547, 51.522763534334075], [13.821887052052432, 52.52975205053502]],
        87: [[10.865932516215679, 52.108632120747615], [13.177060108169343, 53.27652243847166]],
        88: [[12.87315449224377, 51.18629399313747], [14.317887949005438, 51.93547765120712]],
    }

    // ---------------------------------------- Create Map ----------------------------------------
    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/deharde/cl8tzajgm008s14rs56k2voj7',
            center: [lng, lat],
            zoom: zoom
        });
        setTimeout(() => map.current.resize(), 0)
    }, []);

    // ---------------------------------------- Initialization ----------------------------------------
    useEffect(async () => {
        map.current.on('load', async () => {
            setMapCallbacks()
            await initializeLayers()
            setMapFinishedLoading(true)
        })
    }, [])

    // ---------------------------------------- Map Event Callbacks ----------------------------------------
    const setMapCallbacks = () => {
        map.current.on('click', function (e) {
            var coordinates = e.lngLat
            console.log(coordinates)
        })
            //onclick for stations => displays details in right drawer
        map.current.on('click', ['stations-layer', 'naturraumgruppen_fill'], async (e) => {
            if (_.isEmpty(e)) {
                return
            }

            if (e.features[0].layer.id === 'stations-layer') {
                props.setAggregate(false)
                let fixedCoords = e.features[0].geometry.coordinates
                fixedCoords[1] = fixedCoords[1] - 0.012
                map.current.flyTo({center: fixedCoords, zoom:13})
                map.current.setPaintProperty('stations-layer', 'circle-radius', 4)
                map.current.setPaintProperty('stations-layer', 'circle-color', 'red')
                const geoDataId = e.features[0].properties.id
                const station = await stationService.getStationById(geoDataId)
                console.log(station)
                props.setSelectedStation(station)
                //console.log(e)
                //const stationDetails = await setSelectedStationDetails(e)
                //await updateNearbyEfls(stationDetails._id)
            }
            else if (e.features[0].layer.id === 'naturraumgruppen_fill') {
                props.setAggregate(true)
                console.log(e.features[0].properties.NR_GR)
                console.log(e.features[0].properties.NR_ID)
                console.log(e.features[0].properties.REGION)
                props.setSelectedNrgName(e.features[0].properties.NR_GR)
                props.setSelectedNrg(e.features[0].properties.NR_ID)
            }
        })

        //after moving to station, fire flyend event
        map.current.on('moveend', ({ originalEvent }) => {
            if (originalEvent) {
                map.current.fire('usermoveend');
            } else {
                map.current.fire('flyend');
            }
        });

        //query only after flying, when zoom level allows the layer to be visualized
        map.current.on('flyend', (e) => {
            setEflDetails(e)
        })

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.current.on('mouseenter', 'naturraumgruppen_fill', () => {
            map.current.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        map.current.on('mouseleave', 'naturraumgruppen_fill', () => {
            map.current.getCanvas().style.cursor = '';
        });

        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    }

    // ---------------------------------------- Initialize Layers  ----------------------------------------
    const initializeLayers = async () => {
        //highlighted EFLs
        /*map.current.addSource('highlighted-efls', {
            type: 'vector',
            url: 'mapbox://deharde.efl_fragment2'
        })
        map.current.addLayer({
            'id': 'highlighted-efls',
            'type': 'line',
            'source': 'highlighted-efls',
            'source-layer': 'efl',
            'paint': {
                "line-color": '#ff0000',
                "line-width": 2,
            },
            //initial filter shows nothing!
            'filter': ['in', ['get', 'EFLID'], ['literal', '']]
        })*/

        //stations
        const data = await stationService.getStationsGeoData()
        console.log(data)
        const featureCollection = {
            type: 'FeatureCollection',
            features: data
        };
        featureCollection.features.forEach(feature => {
            feature.properties = {
                id: feature._id
            }
        })
        map.current.addSource('stations', {
            type: 'geojson',
            data: featureCollection
        });
        map.current.addLayer({
            'id': 'stations-layer',
            'type': 'circle',
            'source': 'stations',
            'paint': {
                'circle-radius': 4,
                'circle-stroke-width': 2,
                'circle-color': 'red',
                'circle-stroke-color': 'white'
            }
        });
        map.current.addLayer({
            'id': 'selected-station',
            'type': 'circle',
            'source': 'stations',
            'paint': {
                'circle-radius': 8,
                'circle-stroke-width': 4,
                'circle-color': 'white',
                'circle-stroke-color': 'red'
            },
            //initial filter shows nothing!
            'filter': ['in', ['get', 'id'], ['literal', '']]
        })
        map.current.addSource('highlighted-nrg', {
            type: 'vector',
            url: 'mapbox://deharde.54lrp3sm'
        });
        map.current.addLayer({
            'id': 'selected-nrg',
            'type': 'fill',
            'source': 'highlighted-nrg',
            'source-layer': 'NRG_2-b9hqpx',
            'paint': {
                'fill-color': '#4289d1',
                'fill-opacity': 0.2,
            },
            //initial filter shows nothing!
            'filter': ['in', ['get', 'id'], ['literal', '']]
        })
        props.setStations(featureCollection)
    }

    // ---------------------------------------- Utils ----------------------------------------
    //zoom when nrg is changed
    useEffect(() => {
        if (hasMapFinishedLoading && props.selectedNrg !== '') {
            console.log(props.selectedNrg)
            const sw = nrgCenterpoints[props.selectedNrg][0]
            const ne = nrgCenterpoints[props.selectedNrg][1]
            map.current.fitBounds([
                sw, // southwestern corner of the bounds
                ne
            ])
        }
    }, [props.selectedNrg])

    //prevents map flickering when resizing
    useEffect(() => {
        setTimeout(() => map.current.resize(), 0)
    }, [props.graphHeight]);

    const setEflDetails = (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
            layers: ['efl']
        });
        if (features.length !== 0) {
            props.setSelectedEfl(features[0].properties)
        }
    }

    useEffect(() => {
        if (props.observationCounts !== null) {
            const ids = props.observationCounts.geoDataIds
            const counts = props.observationCounts.obsCounts
            let maxSize = Math.max(...props.observationCounts.obsCounts)

            map.current.setPaintProperty('stations-layer', 'circle-radius',
                [
                    'interpolate', ['linear'], //interpolation params,
                    ['number',
                        ["at",
                            ["case",
                                ["!=", ["index-of", ["get", "id"], ["literal", ids]], -1],
                                ["index-of", ["get", "id"], ["literal", ids]],
                                0],
                            ["literal", counts]]], // gets ID from each feature, looks up the index in ids, retrieves value at size array
                    1, //stop input 1 -- always 0
                    4, //stop output 1 -- default size 4
                    maxSize, //stop input 2 -- max nr of beobachtungen
                    24, //stop output 2 -- reasonable looking px size?
                ])

            map.current.setPaintProperty('stations-layer', 'circle-color',
                [
                    'case',
                        //cond1
                        ['all',
                            //bool1
                            ["!=", ["index-of", ["get", "id"], ["literal", ids]], -1],
                            //bool2
                            ["!=",
                                ['at',
                                    ["index-of", ["get", "id"], ["literal", ids]],
                                    ["literal", counts]
                                ],
                                0
                            ]
                        ],
                    //output1
                    'red',
                    //fallback
                    '#8c8c8c'
                ])
        }
        console.log("paint properties updated")
    }, [props.observationCounts]);

    useEffect(() => {
        console.log(props.selectedStation)
        if (hasMapFinishedLoading) {
            if (props.selectedStation !== null) {
                map.current.setFilter('selected-station', ['in', ['get', 'id'], ['literal', props.selectedStation.geodata._id.toString()]])
                map.current.setFilter('selected-nrg', ['in', ['get', 'NR_ID'], ['literal', '']])
            }
            else {
                map.current.setFilter('selected-station', ['in', ['get', 'id'], ['literal', '']])
            }
        }
    }, [props.selectedStation])

    useEffect(() => {
        console.log(props.selectedNrg)
        if (hasMapFinishedLoading) {
            if (props.selectedNrg !== '') {
                map.current.setFilter('selected-nrg', ['in', ['get', 'NR_ID'], ['literal', props.selectedNrg.toString()]])
                map.current.setFilter('selected-station', ['in', ['get', 'id'], ['literal', '']])
            }
            else {
                map.current.setFilter('selected-nrg', ['in', ['get', 'NR_ID'], ['literal', '']])
            }
        }
    }, [props.selectedNrg])

    return (
        <div ref={mapContainer} //className="map-container"
             style={{
                 height: `${(props.isMapOpen) ? ("100%") : ("0%")}`,
        }}>
            <div>
                <img style={{
                    zIndex: 1,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    marginTop: 15,
                    marginRight: 15,
                    height: 60,
                    width: 60,
                }}
                     src={closeMapboxIcon}
                     onClick={() => props.setMapOpen(false)}
                />
            </div>
        </div>
    );
}