import React, { useRef, useEffect, useState, useCallback } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Graph from '../graph/graph'
import LaiGraph from '../graph/laiGraph'
import ObservationsGraph from "../graph/observationsGraph";
import * as d3 from "d3"
import {leftDrawerWidth, modelParams} from "../../App"
import {rightDrawerWidth} from "../../App"
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import BasicTable from "../table/table";
import {TextField} from "@mui/material";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0
    },
    toolbar: theme.mixins.toolbar,
}));

export const initialDrawerHeight = 200;


export const GraphContainer = (props) => {
    const rootHeight = document.getElementById("root").getBoundingClientRect().height;
    const rootWidth = document.getElementById("root").getBoundingClientRect().width;
    const classes = useStyles();

    const [drawerPxHeight, setDrawerPxHeight] = useState(0);
    const [drawerPxWidth, setDrawerPxWidth] = useState(0);
    const [tabValue, setTabValue] = useState(0)
    const [drawerCalcHeight, setDrawerCalcHeight] = useState("0%")
    const [drawerCalcWidth, setDrawerCalcWidth] = useState("0%")
    const [percentage, setPercentage] = useState(0.7)
    const [laiMin, setLaiMin] = useState(0.0)
    const [laiMax, setLaiMax] = useState(7.0)
    const [draggerActive, setDraggerActive] = useState(false)


    const handleMouseDown = e => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
        setDraggerActive(true)
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
        setDraggerActive(false)
    };



    const calculateDrawerWidth = () => {
        let subtractFromWidth = 0
        if (props.isSidebarOpen && props.isDetailsViewOpen) subtractFromWidth = 2 * leftDrawerWidth
        else if (props.isSidebarOpen || props.isDetailsViewOpen) subtractFromWidth = leftDrawerWidth
        return rootWidth - subtractFromWidth
    }

    const calculateDrawerHeight = () => {

    }

    const calculateMarginLeft = () => {
        if (props.isSidebarOpen) return leftDrawerWidth + 'px'
        else return '0px'
    }

    const calculateMarginRight = () => {
        if (props.isDetailsViewOpen) return rightDrawerWidth + 'px'
        else return '0px'
    }

    useEffect(() => {
        let pxWidth = window.innerWidth;
        if (props.isSidebarOpen) pxWidth = pxWidth - leftDrawerWidth
        if (props.isDetailsViewOpen) pxWidth = pxWidth - rightDrawerWidth
        setDrawerPxWidth(pxWidth)

        let pxHeight = window.innerHeight * (props.graphHeight / 100)
        setDrawerPxHeight(pxHeight)

    }, [props.graphHeight, props.isSidebarOpen, props.isDetailsViewOpen])

    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const calculateGraphWidth = () => {
        if (document.getElementById('graph-div') !== null) {
            return document.getElementById('graph-div').clientWidth
        }
       else return 0
    }

    const calculateGraphHeight = () => {
        if (document.getElementById('graph-div') !== null) {
            return document.getElementById('graph-div').clientHeight
        }
        else return 0
    }

    const handleMouseMove = useCallback(e => {
        let availableHeight = (rootHeight - 62)
        let percentage = Math.max(Math.min(((e.clientY - 62) / availableHeight), 1), 0)
        setPercentage(percentage)
        //calc map height
        console.log("total height is " + rootHeight + ", y pos is " + (e.clientY-62) + ", percentage is " + percentage)
    }, []);

    const handleStartDateChange = (event) => {
        props.setStartDate(event.target.value);
    }

    const handleEndDateChange = (event) => {
        props.setEndDate(event.target.value);
    }

    const handleLaiMinChanged = (event) => {
        setLaiMin(event.target.value);
    }

    const handleLaiMaxChanged = (event) => {
        setLaiMax(event.target.value);
    }

    const getGraphAreaContent = () => {

        if (props.selectedModelParam === modelParams[0]) {
            if (props.selectedPlant !== '' &&
                _.isEmpty(props.observations) &&
                !props.loadingPlants &&
                !props.loadingObservations) {
                return (<div style={{position: "absolute", top: "50%", left: "50%"}}>Keine Beobachtungsdaten verfügbar</div>)
            }
            else if (props.selectedPlant === '' && !props.loadingPlants) {
                return (<div style={{position: "absolute", top: "50%", left: "50%"}}>Bitte Pflanzenart auswählen!</div>)
            }
            else if (props.selectedPlant === '' && props.loadingPlants) {
                return (<div style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress /></div>)
            }
            else if (props.loadingObservations) {
                return (<div style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress /></div>)
            }
            else return (<ObservationsGraph
                laiSeries={props.laiSeries}
                observations={props.observations}
                height={calculateGraphHeight()}
                width={calculateGraphWidth()}
                startDate={props.startDate}
                endDate={props.endDate}
                selectedPlant={props.selectedPlant}
            />)
        }
        else if (props.selectedModelParam === modelParams[1]) {
            if (props.selectedPlant !== '' &&
                _.isEmpty(props.laiSeries.data) &&
                !props.loadingPlants &&
                !props.loadingLaiSeries) {
                return (<div style={{position: "absolute", top: "50%", left: "50%"}}>Keine LAI Daten verfügbar</div>)
            }
            if (props.selectedPlant === '' && !props.loadingPlants) {
                return (<div style={{position: "absolute", top: "50%", left: "50%"}}>Bitte Pflanzenart auswählen!</div>)
            }
            if (props.selectedPlant === '' && props.loadingPlants) {
                return (<div style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress /></div>)
            }
            if (props.loadingLaiSeries) {
                return (<div style={{position: "absolute", top: "50%", left: "50%"}}><CircularProgress /></div>)
            }
            else return (
                    <LaiGraph
                        laiSeries={props.laiSeries}
                        observations={props.observations}
                        height={calculateGraphHeight()}
                        width={calculateGraphWidth()}
                        startDate={props.startDate}
                        endDate={props.endDate}
                        selectedPlant={props.selectedPlant}
                        laiMin={laiMin}
                        laiMax={laiMax}
                        draggerActive={draggerActive}
                    />
            )
        }
    }

    return (
        <div
            style={{
                position: "relative",
                height: `${(props.isMapOpen) ? "0%" : "calc(100% - 62px)"}`,
                marginTop: "62px",
            }}>
            <div
                id="graph-div"
                style={{
                    position: "relative",
                    marginLeft: `${calculateMarginLeft()}`,
                    right: `${calculateMarginRight()}`,
                    width: `${calculateDrawerWidth()}`,
                    height: `${percentage * 100}%`,
                    border: "1px solid #000000",

                }}>
                {
                    getGraphAreaContent()
                }
            </div>
            {
                //DRAGGER
            }
            <div onMouseDown={e => handleMouseDown(e)}
                 style={{
                     width: `${calculateDrawerWidth()}`,
                     visibility: `${(props.isMapOpen) ? 'hidden' : 'visible'}`,
                     height: "5px",
                     cursor: "ns-resize",
                     borderTop: "1px solid #ddd",
                     position: "relative",
                     right: 0,
                     bottom: 0,
                     marginLeft: `${calculateMarginLeft()}`,
                     zIndex: 500,
                     backgroundColor: "#f4f7f9"
                 }}/>

            <div
                style={{
                    position: "relative",
                    height: `${(1-percentage) * 100}%`,
                    marginLeft: `${calculateMarginLeft()}`,
                    right: `${calculateMarginRight()}`,
                    width: `${calculateDrawerWidth()}`,
                    bottom: "0px",
                    border: "1px solid #000000",
                    overflow: "auto"
                }}>
                <div style={{
                    display: "flex",
                }}>
                    <TextField
                        style={{
                            margin: "20px",
                        }}
                        id="date"
                        label="Datum Beginn"
                        type="date"
                        defaultValue="1991-01-01"
                        onChange={handleStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        style={{
                            margin: "20px"
                        }}
                        id="date"
                        label="Datum Ende"
                        type="date"
                        defaultValue="2022-01-01"
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        style={{
                            margin: "20px",
                            width: "100px",
                        }}
                        inputProps={{ step: ".1", min: "0.0", max: "10.0"}}
                        id="laimin"
                        label="LAI Minimum"
                        type="number"
                        defaultValue="0.0"
                        onChange={handleLaiMinChanged}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        style={{
                            margin: "20px",
                            width: "100px",
                        }}
                        inputProps={{ step: ".1", min: "0.0", max: "10.0"}}
                        id="laimax"
                        label="LAI Maximum"
                        type="number"
                        defaultValue="7.0"
                        onChange={handleLaiMaxChanged}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    </div>
                <BasicTable
                    isMapOpen={props.isMapOpen}
                    observations={props.observations}>
                </BasicTable>
                </div>

            </div>
    )
}
