import axios from 'axios'

const BASE_API_URL = process.env.REACT_APP_BACKEND_URI + '/api/efls/'
const PHAENO_APP_API_KEY = process.env.REACT_APP_PHAENO_APP_API_KEY

const getNearbyEfls = async (_id, radius) => {
  let data;
  try {
      const response = await axios.get(BASE_API_URL, {
          params: { _id: _id, radius: radius },
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          },
      });
      data = response.data;
  } catch (error) {
      console.error('Error fetching data:', error);
  }
  return data;
}

const eflService = {
    getNearbyEfls,
}

export default eflService