import React, {useRef, useEffect, useState} from "react";
import * as d3 from 'd3'
import _ from 'lodash'
import {getPhaseColour} from "../../App";

const ObservationsGraph = (props) => {
    const padding = 80

    const svgRef = useRef()

    const [lineIds, setLineIds] = useState([])
    const [labelIds, setLabelIds] = useState([])
    const [formattedObservations, setFormattedObservations] = useState([])
    const [labelsObservations, setLabelsObservations] = useState([])
    const [colours, setColours] = useState([])


    const dayOfYear = (date) => {
        return Math.floor((date - new Date(date.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24);
    }

    const isDateWithinDateRange = (date) => {
        return new Date(props.startDate) - date < 0 && new Date(props.endDate) - date > 0
    }

    useEffect(() => {
        if (props.observations !== []) {
            const observations = props.observations
            console.log(observations)
            let phaseIds = []
            let sortedByType = []
            let obsLabels = []
            let obsColours = []

            let obsDataTemplate=[]
            let startYear = new Date(props.startDate).getFullYear()
            let endYear = new Date(props.endDate).getFullYear()
            for (let i = startYear; i < endYear; i++) {
                obsDataTemplate.push({
                    year: i,
                    day: 0,
                    size: 0,
                })
            }
            console.log(obsDataTemplate)

            for (let i = 0; i < observations.length; i++) {
                    const date = new Date(observations[i].metadata.date)
                    if (isDateWithinDateRange(date)) {
                        if (!phaseIds.includes(observations[i].phase_id.phase_id)) {
                            phaseIds.push(observations[i].phase_id.phase_id)
                            obsLabels.push(observations[i].phase_id.name)
                            obsColours.push(getPhaseColour(observations[i].phase_id.phase_id))
                            sortedByType.push([])
                            for (let k = 0; k < obsDataTemplate.length; k++) {
                                sortedByType[sortedByType.length - 1].push({
                                    year: obsDataTemplate[k].year,
                                    day: obsDataTemplate[k].day,
                                    size: 0
                                })
                            }
                        }
                        let index = phaseIds.indexOf(observations[i].phase_id.phase_id)
                        let element = sortedByType[index].find(({year}) => year === date.getFullYear())

                        if (element !== undefined) {
                            element.day = (element.size * element.day + dayOfYear(date)) / (element.size + 1)
                            element.size = element.size + 1
                        }
                        else {
                            sortedByType[index].push({
                                year: date.getFullYear(),
                                day: dayOfYear(date),
                                size: 1,
                            })
                        }
                    }
                }
            console.log(sortedByType)
            /*
            for (let i = 0; i < sortedByType.length; i++) {
                sortedByType[i] = sortedByType[i].sort(function(a, b) {
                    let keyA = a.year
                    let keyB = b.year
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }*/

            setLabelsObservations(obsLabels)
            setColours(obsColours)
            setFormattedObservations(sortedByType)
        }
    }, [props.observations, props.startDate, props.endDate])

    const createObsLines = (xScale, yScale) => {
        console.log(formattedObservations)
        console.log(colours)
        console.log(labelsObservations)
        //function for line
        const observationLine = d3.line()
            .x(function(d) { return xScale(d.year) })
            .y(function(d) { return yScale(d.day) })
            .defined(function(d) { return !(d.size === 0); })
        //remove old
        for (let i = 0; i < lineIds.length; i++) {
            d3.select(svgRef.current)
                .selectAll('#o' + i)
                .remove()
        }
        for (let i = 0; i < labelIds.length; i++) {
            d3.select(svgRef.current)
                .selectAll('#l' + i)
                .remove()
            d3.select(svgRef.current)
                .selectAll('#t' + i)
                .remove()
            d3.select(svgRef.current)
                .selectAll('g')
                .remove()
        }
        //create new
        let newLineIds = []
        for (let i = 0; i < formattedObservations.length; i++) {
            let filteredObs = formattedObservations[i].filter((item => item.size !== 0))
            d3.select(svgRef.current)
                .append('g')
                .selectAll('g')
                .data(filteredObs)
                .enter()
                .append('circle')
                .attr("cx", function (d) { return xScale(d.year); } )
                .attr("cy", function (d) { return yScale(d.day); } )
                .attr("r", 3)
                .style("fill", colours[i])
            for (let j = 0; j < formattedObservations[i].length; j++) {

            }
            d3.select(svgRef.current)
                .append('path')
                .attr('class', 'obs')
                .attr('id', "o" + i)
                .attr('d', (value) => observationLine(formattedObservations[i]))
                .attr('fill', 'none')
                .attr('stroke', colours[i])
                .attr('stroke-width', '1')
            newLineIds.push(i)
        }

        let newLabelIds = []
        let padding = 0
        for(let i = 0; i < labelsObservations.length; i++) {
            let size = 20
            let xpos = Math.trunc(i / 2)
            let ypos = i % 2
            if (i >= 2 && i % 2 === 0) {
                //calc width of previous elements
                let t0 = d3.select(svgRef.current).selectAll('#t' + (i - 2))
                let t1 = d3.select(svgRef.current).selectAll('#t' + (i - 1))
                let t0box = t0.node().getBBox()
                let t1box = t1.node().getBBox()
                padding += Math.max(t0box.width, t1box.width) + size + 20
            }
            d3.select(svgRef.current)
                .append("rect")
                .attr('id', "l" + i)
                .attr("x", 100 + padding)
                .attr("y", 25 + ypos*(size+5)) // 100 is where the first dot appears. 25 is the distance between dots
                .attr("width", size)
                .attr("height", size)
                .style("fill", colours[i])
            d3.select(svgRef.current)
                .append("text")
                .attr('id', "t" + i)
                .attr("x", 100  + padding + size + 5)
                .attr("y", 25 + ypos*(size+5) + (size/2)) // 100 is where the first dot appears. 25 is the distance between dots
                .style("fill", '#000000')
                .text(labelsObservations[i])
                .attr("text-anchor", "left")
                .style("alignment-baseline", "middle")
                .style("font-size", "12px")
            newLabelIds.push(i)
        }

        setLineIds(newLineIds)
        setLabelIds(newLabelIds)
    }

    useEffect(() => {
        if (!_.isEmpty(formattedObservations)) {
            // X SCALE
            const xScale = d3.scaleLinear()
                .domain([new Date(props.startDate).getFullYear(), new Date(props.endDate).getFullYear()])
                .range([0+padding, props.width-padding])

            const yScale = d3.scaleLinear()
                .clamp(true)
                .domain([0, 366])
                //highest to lowest
                .range([props.height-padding, padding])

            // LINES
            //provides coords for each data point that can be used to draw via the d attribute
            //lai data series

            //DRAW THE LINES
            //add the attribute "d" to the "path" element
            //remember: path element uses d to draw line

            createObsLines(xScale, yScale)

            //X AND Y AXIS
            //provides coords for each axis
            const xAxis = d3.axisBottom(xScale)
            const yAxis = d3.axisLeft(yScale)

            //DRAW X AND Y AXIS
            d3.select('#xaxis').remove()
            d3.select(svgRef.current)
                .append('g')
                .attr('transform', `translate(0,${props.height-padding})`)
                .attr('id', 'xaxis')
                .call(xAxis
                    .tickFormat(d3.format("d")))
                    //.tickFormat(d3.timeFormat("%d-%m-%g")))
                    //.ticks(10))
                    //.tickFormat(d3.timeFormat("%Y-%m-%d"))
                    //.tickValues(chartData.map(function(d) { return new Date(d.date)}) )
                .selectAll("text")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-65)")
                .style("font-size", "10px")
            let xAxisBBox = d3.select(svgRef.current)
                .selectAll('#xaxis')
                .node()
                .getBBox()
            let translateX = d3.select(svgRef.current)
                .selectAll('#xaxis')
                .attr('transform')
            d3.select(svgRef.current)
                .selectAll("#xAxisLabel")
                .remove()
            d3.select(svgRef.current)
                .append("text")
                .attr('id', 'xAxisLabel')
                .attr('transform', translateX)
                .attr('x', xAxisBBox.width)
                .attr('y', xAxisBBox.height + 25)
                .style("fill", '#000000')
                .text("Jahr")
                .attr("text-anchor", "left")
                .style("alignment-baseline", "middle")
                .style("font-size", "14px")

            d3.select('#yaxis').remove()
            d3.select(svgRef.current)
                .append('g')
                .attr('transform', `translate(${padding},0)`)
                .attr('id', 'yaxis')
                .call(yAxis)
            d3.select('#yaxis').remove()
            d3.select(svgRef.current)
                .append('g')
                .attr('transform', `translate(${padding},0)`)
                .attr('id', 'yaxis')
                .call(yAxis)
            let yAxisBBox = d3.select(svgRef.current)
                .selectAll('#yaxis')
                .node()
                .getBBox()
            let translateY = d3.select(svgRef.current)
                .selectAll('#yaxis')
                .attr('transform')
            console.log(translateY)
            d3.select(svgRef.current)
                .selectAll("#yAxisLabel")
                .remove()
            d3.select(svgRef.current)
                .append("text")
                .attr('id', 'yAxisLabel')
                .attr('transform', 'rotate(-90) translate(-170, 30)')
                .attr('x', 0)
                .attr('y', 0)
                .style("fill", '#000000')
                .text("Tag im Jahr")
                .attr("text-anchor", "left")
                .style("alignment-baseline", "middle")
                .style("font-size", "14px")
        }

    }, [props.laiSeries, props.startDate, props.endDate, formattedObservations, props.height, props.width])

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    return (
        <div id="wrapper" style={{position: "relative", width: "100%", height: "100%"}}>
            <svg
                id="chart"
                style={{position: "relative", width: "100%", height: "100%"}}
                ref={svgRef}
                viewBox={`0 0 ${props.width} ${props.height}`} >
                <path id="lai" d="" fill="none" stroke="red" strokeWidth="1" />
            </svg>
        </div>
    )
}

export default ObservationsGraph;