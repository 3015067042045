import {
    ResponsiveContainer,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    LineChart, ReferenceLine, ReferenceArea
} from "recharts";
import {PureComponent, useEffect, useState} from "react";

export default function Graph(props) {
    const [refAreaLeft, setRefAreaLeft] = useState("")
    const [refAreaRight, setRefAreaRight] = useState("")
    const [refLines, setRefLines] = useState(null)
    const [laiData, setLaiData] = useState(null)
    const [dateTicksX, setDateTicksX] = useState([null])
    const [dateTicksText, setDateTicksText] = useState([null])

    const createReferenceLines = (observations, startDate) => {
        let data = []
        observations.forEach((observation) => {
            let elapsedDays = Math.round((new Date(observation.metadata.date) - new Date(startDate)) / (1000 * 60 * 60 * 24))
            if (elapsedDays > 0) {
                data.push({
                    date: elapsedDays,
                    test: 1
                })
            }
        })
        let newLines = Object.entries(data).map(([key, value]) => {
            return <ReferenceLine key={key} x={data[key].date} stroke="green" label={{ value: "", position: 'top' }}/>
        })
        console.log(newLines)
        setRefLines(newLines)
        //https://codesandbox.io/s/recharts-linechart-with-referenceline-icon-and-tooltip-cmthu?file=/src/Chart.js
    }

    const setDateTickData = (laiSeries) => {
        let startDate = laiSeries.start_date
        let dateRange = laiSeries.data.length
        let tickAmount = Math.round(dateRange / 365.2465)
        let ticks = []
        for (let i = 0; i < tickAmount; i++) {
            ticks.push(startDate)
        }
    }

    useEffect(
        () => {
                if (props.observations.length !== 0 && props.laiSeries.start_date) {
                    createReferenceLines(props.observations, props.laiSeries.start_date)
                    setDateTickData(props.laiSeries)
                }
        }, [props.observations, props.laiSeries]
    )

    useEffect(() => {
        if (props.laiSeries.start_date) {
            let dataArray = []
            dataArray.push({
                date: new Date(props.laiSeries.start_date),
                value: props.laiSeries.data[0]
            })
            for (let i = 1; i < props.laiSeries.data.length; i++) {
                let next = new Date(dataArray[i - 1].date)
                next.setDate(next.getDate() + 1)
                dataArray.push({
                    date: next,
                    value: props.laiSeries.data[i].value
                })
            }
            console.log(dataArray)
            setLaiData(dataArray)
        }
    }, [props.laiSeries])

    class CustomizedAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload } = this.props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                        {payload.value}
                    </text>
                </g>
            );
        }
    }

    const createXAxisTicks = (laiSeries) => {
        let startDate = laiSeries.start_date
        let dateRange = laiSeries.data.length
        let tickAmount = Math.round(dateRange / 365.2465)
        let ticks = []
        for (let i = 0; i < tickAmount; i++) {
            ticks.push(startDate)
        }
        return ticks.map(([key, value]) => {
            return (
                <g transform={`translate(${0},${0})`}>
                    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                        {ticks[key]}
                    </text>
                </g>
            )
        })
    }

    return (props.open && props.observations != null) ?
        (<ResponsiveContainer width="95%" height="95%">
            <LineChart
                width={500}
                height={300}
                //data={laiData}
                data={props.laiSeries.data}
                margin={{
                        top: 30,
                        right: 10,
                        left: 10,
                        bottom: 10,
                }}
                onMouseDown={(e) =>
                    setRefAreaLeft(e.activeLabel)
                }
                onMouseMove={(e) =>
                    setRefAreaRight(e.activeLabel)
                }
            >

                    <XAxis dataKey="date" type="number" />
                    <YAxis />
                <Tooltip content={<div><p>"test"</p></div>}/>
                {refLines}
                {refAreaLeft && refAreaRight ? (
                    <ReferenceArea
                        yAxisId="1"
                        x1={refAreaLeft}
                        x2={refAreaRight}
                        strokeOpacity={0.3}
                    />
                ) : null}
                <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#8884d8"
                    dot={false}
                    isAnimationActive={false}
                />
            </LineChart>
        </ResponsiveContainer>)

         :
        (<div>

        </div>)
}