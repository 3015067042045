import axios from 'axios'

const BASE_API_URL = process.env.REACT_APP_BACKEND_URI + '/api/stations/'
const PHAENO_APP_API_KEY = process.env.REACT_APP_PHAENO_APP_API_KEY

const getStationsGeoData = async () => {
    console.log(BASE_API_URL)
    let data
    try {
        await axios.get(BASE_API_URL + 'geoData', {
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          },
        }).then(function (response) {
            data = response.data
        })
    }
    catch (error) {
        return []
    }
    if (data.hasOwnProperty('stack')) return []
    return data
}

const getStationById = async (geoDataId) => {
    let data
    try {
        await axios.get(BASE_API_URL, {
          params: { geodata: geoDataId }, 
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          },
        }) .then(function (response) {
            data = response.data
        })
    }
    catch (error) {
        return []
    }
    if (data.hasOwnProperty('stack')) return []
    return data
}

const getAllStations = async () => {
    let data
    try {
        await axios.get(BASE_API_URL + "all/", {
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          },
        }) .then(function (response) {
            data = response.data
        })
        .catch(function (err) {
            return []
        })
    }
    catch (error) {
        return []
    }
    if (data.hasOwnProperty('stack')) return []
    return data
}

const getStationObservationCount = async (nrgId, plantId) => {
    let data
    try {
        await axios.get(BASE_API_URL + 'obsCounts/', {
          params: { nrg_id: nrgId , plant_id: plantId},
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          },
        })
            .then(function (response) {
                data = response.data
            })
    }
    catch (error) {
        return null
    }
    return data
}

const getAvailableFfg = async (station_id) => {
    let data
    try {
        await axios.get(BASE_API_URL + "ffg/", {
          params: { _id: station_id },
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          },
        }).then(function (response) {
            data = response.data
        })
    }
    catch (error) {
        return []
    }
    return data
}

const stationService = {
    getStationsGeoData,
    getAllStations,
    getStationById,
    getAvailableFfg,
    getStationObservationCount,
}

export default stationService