import axios from 'axios'
const qs = require('qs');

const BASE_API_URL = process.env.REACT_APP_BACKEND_URI + '/api/lai/query/'
const PHAENO_APP_API_KEY = process.env.REACT_APP_PHAENO_APP_API_KEY

const getLaiSeriesAcker = async (station_id, radius, lntz_id, plant_id) => {
    let data
    try {
        await axios.get(BASE_API_URL, {
          params: { _id: station_id, radius: radius, lntz_id: lntz_id, plant_id: plant_id },
          headers: {
            'x-api-key': PHAENO_APP_API_KEY
          }, }).then(function (response) {
              data = response.data
          })
    }
    catch (error) {
        return []
    }
    return data
}

const getLaiSeriesNonAcker = async (station_id, radius, lntz_id) => {
    let data
    try {
        await axios.get(BASE_API_URL + "getNonAcker/", {
          params: { _id: station_id, radius: radius, lntz_id: lntz_id },
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          }, 
         }).then(function (response) {
            data = response.data
        })
    }
    catch (error) {
        return []
    }
    return data
}

function roughSizeOfObject(object) {
    const objectList = [];
    const stack = [object];
    let bytes = 0;

    while (stack.length) {
        const value = stack.pop();

        switch (typeof value) {
            case 'boolean':
                bytes += 4;
                break;
            case 'string':
                bytes += value.length * 2;
                break;
            case 'number':
                bytes += 8;
                break;
            case 'object':
                if (!objectList.includes(value)) {
                    objectList.push(value);
                    for (const prop in value) {
                        if (value.hasOwnProperty(prop)) {
                            stack.push(value[prop]);
                        }
                    }
                }
                break;
        }
    }

    return bytes / 1000000;
}

const laiService = {
    getLaiSeriesAcker,
    getLaiSeriesNonAcker,
}

export default laiService