import axios from 'axios'

const BASE_API_URL = process.env.REACT_APP_BACKEND_URI + '/api/observations/'
const PHAENO_APP_API_KEY = process.env.REACT_APP_PHAENO_APP_API_KEY

const getObservationData = async (stationId, plantId) => {
    let data
    try {
        await axios.get(BASE_API_URL, {
            params: { station_id: stationId , plant_id: plantId},
            headers: {
              'x-api-key': PHAENO_APP_API_KEY,
            },
          }).then(function (response) {
                data = response.data
          })
    }
    catch (error) {
        return []
    }
    return data
}

const getAggregatedObservationsData = async (nrgId, plantId) => {
    let data
    try {
        await axios.get(BASE_API_URL + 'aggregated/', {
          params: { nrg_id: nrgId , plant_id: plantId},
          headers: {
            'x-api-key': PHAENO_APP_API_KEY,
          }
         }).then(function (response) {
            data = response.data
        })
    }
    catch (error) {
        return []
    }
    return data
}



const observationService = {
    getObservationData,
    getAggregatedObservationsData,
}

export default observationService