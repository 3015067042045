import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {getNrgName, leftDrawerWidth, modelParams, nrgIds} from "../../App.js"
import TabObservations from "./TabObservations";
import TabLai from "./TabLai"
import mapboxButtonIcon from "../../icons/open_mapbox_icon.png"
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import closeMapboxIcon from "../../icons/close_mapbox_icon.png";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {FormControl, FormGroup, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Button} from "@mui/material";
import {Info, Clear} from "@material-ui/icons";
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${leftDrawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${leftDrawerWidth}px)`,
        marginLeft: `${leftDrawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Sidebar = (props) => {
    const theme = useTheme();
    const open = props.sidebarOpen;

    const handleDrawerOpen = () => {
        props.openSidebar(true);
    };

    const handleDrawerClose = () => {
        props.openSidebar(false);
    };
    const handleSelectedPlantChange = (event) => {
        const plant = props.availablePlants.find((el) => el._id === event.target.value)
        props.setSelectedPlant(plant)
    }

    const handleNrgIdChange = (event) => {
        props.setSelectedNrg(event.target.value);
        props.setSelectedNrgName(getNrgName(event.target.value))
    };

    const handleSelectedParamChange = (event) => {
        props.setSelectedModelParam(modelParams[event.target.value]);
    };

    const handleSelectedStationChange = (event) => {
        let selectedStation = props.allStations.filter((stat) => stat._id === event.target.value)
        props.setSelectedStation(selectedStation[0]);
    };

    const [stationInfoOpen, setStationInfoOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setStationInfoOpen(false)
        setAnchorEl(null);
    };

    const handleDwdCheckboxChange = (event) => {
        if (!(!props.iNatChecked && !event.target.checked)) {
            props.setDwdChecked(event.target.checked)
        }
    }

    const handleINatCheckboxChange = (event) => {
        if (!(!props.dwdChecked && !event.target.checked)){
            props.setINatChecked(event.target.checked)
        }
    }

    React.useEffect(() => {
        if (props.dwdChecked && props.iNatChecked) {
            console.log("lod1")
            props.setLod(1)
        }
        if (props.dwdChecked && !props.iNatChecked) {
            console.log("lod0")
            props.setLod(0)
        }
        if (!props.dwdChecked && props.iNatChecked) {
            console.log("lod1")
            props.setLod(1)
        }
    }, [props.dwdChecked, props.iNatChecked])

    const showAvailablePlants = () => {
        if (props.availablePlants !== []) {
            if (props.availablePlants.length === 0) {
                return (<MenuItem disabled>
                    <em>Keine Pflanzen an dieser Station verfügbar!</em>
                </MenuItem>)
            }
            else {
                const menuItems = props.availablePlants.map((value) => {
                    return <MenuItem value={value._id} key={value._id}>
                        <em>{value.name}</em>
                    </MenuItem>
                })
                return menuItems
            }
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        WebApp zur Validierung des ArcEGMO-Modells
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: leftDrawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: leftDrawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider></Divider>

                <FormControl variant="standard" sx={{ m: 1 }} style={{margin: "10px"}}>
                    <InputLabel id="params">Modellparameter</InputLabel>
                    <Select
                        labelId="params_selector_label"
                        id="params_selector"
                        value={modelParams.indexOf(props.selectedModelParam)}
                        onChange={handleSelectedParamChange}
                        label="Visualisierter Modellparameter"
                    >
                        {
                            modelParams.map((value) => {
                                return (
                                    <MenuItem value={modelParams.indexOf(value)} key={value.name}>
                                        <em>{value.name}</em>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>

                <Divider></Divider>

                <div style={{
                    display: "flex",
                }}>
                    <FormControl variant="standard" sx={{ m: 1 }} style={{margin: "10px", width: "80%"}}>
                        <InputLabel id="plants">Pflanzenart</InputLabel>
                        <Select

                            labelId="plant_selector_label"
                            id="plant_selector"
                            value={props.selectedPlant ? props.selectedPlant._id : ''}
                            onChange={handleSelectedPlantChange}
                            label="Pflanzenart"
                        >
                            {
                                (props.loadingPlants) ?
                                <CircularProgress />
                                :
                                showAvailablePlants()
                            }
                        </Select>
                    </FormControl>
                    <IconButton aria-label="delete" style={{margin: "10px", width: "20%"}} onClick={(event) => {
                        props.handleClearPlant()
                    }}>
                        <Clear />
                    </IconButton>
                </div>


                <Divider></Divider>

                <div style={{
                    display: "flex",
                }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, padding: "10px" }} style={{margin: "10px", width: "80%"}}>
                        <InputLabel id="nrg">Naturraumgruppe</InputLabel>
                        <Select
                            labelId="nrg_id_selector_label"
                            id="nrg_id_selector"
                            value={props.selectedNrg}
                            onChange={handleNrgIdChange}
                            label="Naturraumgruppe"
                        >
                            {
                                (nrgIds.map((value) => {
                                    return (
                                        <MenuItem value={value} key={value}>
                                            <em>{getNrgName(value) + " (" + value + ")" }</em>
                                        </MenuItem>
                                    )
                                }))
                            }
                        </Select>
                    </FormControl>
                    <IconButton aria-label="delete" style={{margin: "10px", width: "20%"}} onClick={(event) => {
                        props.handleClearNrg()
                    }}>
                        <Clear />
                    </IconButton>
                </div>

                <div style={{
                    display: "flex",
                }}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, padding: "10px" }} style={{margin: "10px", width: "90%"}}>
                    <InputLabel id="station">Station</InputLabel>
                    <Select
                        labelId="station_selector_label"
                        id="station_selector"
                        value={(props.selectedStation !== null) ? props.selectedStation._id : ''}
                        onChange={handleSelectedStationChange}
                        label="Station"
                    >
                        {
                            (props.allStations !== []) ? (
                                props.allStations.map((value) => {
                                    let name
                                    if (value.hasOwnProperty("station_name")) name = value.station_name
                                    else name = value.source + ", Stationsnummer: " + props.allStations.indexOf(value)
                                    return (
                                    <MenuItem value={value._id} key={value._id}>
                                        <em>{name}</em>
                                    </MenuItem>
                                    )
                                })
                            )
                            :
                            ({})
                        }
                    </Select>
                    <IconButton aria-label="delete" style={{margin: "10px"}} onClick={(event) => {
                        setStationInfoOpen(!stationInfoOpen)
                        setAnchorEl(event.currentTarget)
                    }}>
                        <Info />
                    </IconButton>
                    <Popover
                        id={"stationInfo"}
                        open={stationInfoOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {
                            (props.selectedStation !== null) ?
                                (
                                    <div>
                                        <Typography sx={{ p: 1 }}>Name: {props.selectedStation.station_name}</Typography>
                                        <Typography sx={{ p: 1 }}>ID: {props.selectedStation.internal_id}</Typography>
                                        <Typography sx={{ p: 1 }}>Naturraum #: {props.selectedStation.nr_id}</Typography>
                                        <Typography sx={{ p: 1 }}>Naturraumgrp. #: {props.selectedStation.nrg_id}</Typography>
                                        <Typography sx={{ p: 1 }}>Quelle: {props.selectedStation.source}</Typography>
                                        <Typography sx={{ p: 1 }}>Lat/Lng: {props.selectedStation.geodata.geometry.coordinates[1]}, {props.selectedStation.geodata.geometry.coordinates[0]}</Typography>
                                    </div>)
                                :
                                (<Typography sx={{ p: 2 }}>Keine Station ausgewählt</Typography>)
                        }

                    </Popover>
                    <Divider></Divider>
                        <FormControlLabel control={<Checkbox checked={props.dwdChecked} onChange={handleDwdCheckboxChange} />} label="DWD" />
                        <FormControlLabel control={<Checkbox checked={props.iNatChecked} onChange={handleINatCheckboxChange}/>} label="iNaturalist" />
                    <Divider></Divider>
                </FormControl>
                    <IconButton aria-label="delete" style={{margin: "10px", width: "20%"}} onClick={(event) => {
                        props.handleClearStation()
                    }}>
                        <Clear />
                    </IconButton>
                </div>


                {
                /*<Divider />
                    <TabLai
                        sidebarOpen={props.sidebarOpen}
                        openSidebar={props.openSidebar}
                        startDate={props.startDate}
                        setStartDate={props.setStartDate}
                        endDate={props.endDate}
                        setEndDate={props.setEndDate}
                        lntzId={props.lntzId}
                        setLntzId={props.setLntzId}
                        availablePlants={props.availablePlants}
                        selectedPlant={props.selectedPlant}
                        setSelectedPlant={props.setSelectedPlant}
                        selectedStation={props.selectedStation}
                        selectedNrg={props.selectedNrg}
                        selectedNrgName={props.selectedNrgName}
                        setSelectedNrg={props.setSelectedNrg}
                        aggregate={props.aggregate}
                    ></TabLai>
                <Divider />*/
                }
                {
                    (props.isMapOpen) ?
                        (<div/>)
                        :
                        (<div style={{display: "flex", justifyContent: "center"}}>
                            <img style={{
                                width: 200,
                                height: 200
                            }}
                                 src={mapboxButtonIcon}
                                 onClick={() => props.setMapOpen(true)} />
                        </div>)
                }

            </Drawer>

        </Box>
    );
}

export default Sidebar;