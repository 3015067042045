import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ShowChart } from '@mui/icons-material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getNrgName } from '../../App'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Divider,
    tableCellClasses
} from "@mui/material";
import _ from "lodash";

const TabLai = (props) => {
    const years = [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
    const lntz = [
        { name: 'Acker', id: 1510 },
        { name: 'Mischwald', id: 1430 },
        //{ name: 'Laubwald', id: 1410 },
    ]
    const nrg = [74, 75, 77, 78, 79, 80, 81, 82, 83, 84, 85, 87, 88, 89]

    const handleStartDateChange = (event) => {
        props.setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        props.setEndDate(event.target.value);
    };

    const handleLntzIdChange = (event) => {
        props.setLntzId(event.target.value);
    };

    const handleSelectedNrgChange = (event) => {
        props.setSelectedNrg(event.target.value)
    }

    const createTable = () => {
        if (props.aggregate && props.selectedNrg !== '') {
            return (
                    <Table sx={{ minWidth: 260 }} aria-label="simple table">
                        <TableBody>
                            <TableRow
                                key={1}
                                sx={{[`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none"
                                    } }}
                            >
                                <TableCell align="left">Typ</TableCell>
                                <TableCell align="left">NRG (Fläche)</TableCell>

                            </TableRow>
                            <TableRow
                                key={2}
                                sx={{[`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none"
                                    } }}
                            >
                                <TableCell align="left">#</TableCell>
                                <TableCell align="left">{props.selectedNrg}</TableCell>
                            </TableRow>
                            <TableRow
                                key={3}
                                sx={{[`& .${tableCellClasses.root}`]: {
                                        borderBottom: "none"
                                    } }}
                            >
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left" >{props.selectedNrgName}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
            )
            /*(
                <List>
                    <ListItem key={props.selectedNrg} >
                        <ListItemText primary={"Naturraumgruppe"}></ListItemText>
                        <ListItemText primary={props.selectedNrg}></ListItemText>
                    </ListItem>
                </List>
            )*/
        }
        else if (!props.aggregate && props.selectedStation !== null) {
            return (
                <TableContainer component={Paper}>
                    <Table stickyHeader sx={{ maxWidth: 260 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">Typ</TableCell>
                                <TableCell align="right">Station</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Quelle</TableCell>
                                <TableCell align="right">DWD</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">ID</TableCell>
                                <TableCell align="right">{props.selectedStation.stat_id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Stationsname</TableCell>
                                <TableCell align="right">{props.selectedStation.stat_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Auflösungsdatum</TableCell>
                                <TableCell align="right">{props.selectedStation.aufl_dat}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">NRG</TableCell>
                                <TableCell align="right">{props.selectedStation.nrg_id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">NRG GR</TableCell>
                                <TableCell align="right">{getNrgName(props.selectedStation.nrg_id)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Latitude</TableCell>
                                <TableCell align="right">{props.selectedStation.lat.toFixed(5)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">Longitude</TableCell>
                                <TableCell align="right">{props.selectedStation.lng.toFixed(5)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>)
                /*<List style={{width: 300}} >
                    <ListItem key={1} >
                        <ListItemText primary={"Typ"}></ListItemText>
                        <ListItemText primary={"Station"}></ListItemText>
                    </ListItem>
                    <ListItem key={2} >
                        <ListItemText primary={"Quelle"}></ListItemText>
                        <ListItemText primary={"DWD"}></ListItemText>
                    </ListItem>
                    <ListItem key={3} >
                        <ListItemText primary={"ID"}></ListItemText>
                        <ListItemText primary={props.selectedStation.stat_id}></ListItemText>
                    </ListItem>
                    <ListItem key={4} >
                        <ListItemText primary={"Stationsname"}></ListItemText>
                        <ListItemText primary={props.selectedStation.stat_name}></ListItemText>
                    </ListItem>
                    <ListItem key={5} >
                        <ListItemText primary={"Auflösungsdatum"}></ListItemText>
                        <ListItemText primary={props.selectedStation.aufl_dat}></ListItemText>
                    </ListItem>
                    <ListItem key={6} >
                        <ListItemText primary={"ID"}></ListItemText>
                        <ListItemText primary={props.selectedStation.stat_id}></ListItemText>
                    </ListItem>
                    <ListItem key={7} >
                        <ListItemText primary={"NRG"}></ListItemText>
                        <ListItemText primary={props.selectedStation.nrg_id}></ListItemText>
                    </ListItem>
                    <ListItem key={8} >
                        <ListItemText primary={"NRG GR"}></ListItemText>
                        <ListItemText primary={getNrgName(props.selectedStation.nrg_id)}></ListItemText>
                    </ListItem>
                    <ListItem key={9} >
                        <ListItemText primary={"Lat"}></ListItemText>
                        <ListItemText primary={`${props.selectedStation.lat.toFixed(5)}`} style={{justifyContent: 'end'}}></ListItemText>
                    </ListItem>
                    <ListItem key={10} >
                        <ListItemText primary={"Lng"}></ListItemText>
                        <ListItemText primary={`${props.selectedStation.lng.toFixed(5)}`} style={{justifyContent: 'end'}}></ListItemText>
                    </ListItem>

                </List>*/
        }
        else return <div></div>
    }

    return (
        <Box sx={{ display: 'flex' }}>
                <List>
                    <ListItem>
                        <TextField
                            id="date"
                            label="Datum Beginn"
                            type="date"
                            defaultValue="1991-01-01"
                            onChange={handleStartDateChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            id="date"
                            label="Datum Ende"
                            type="date"
                            defaultValue="2022-01-01"
                            onChange={handleEndDateChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>

                    <ListItem>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="lntz">Landnutzungstyp</InputLabel>
                            <Select
                                labelId="lntz_id_selector_label"
                                id="lntz_id_selector"
                                value={props.lntzId}
                                onChange={handleLntzIdChange}
                                label="Landnutzungstyp"
                            >
                                {
                                    lntz.map((value) => {
                                        return (
                                            <MenuItem value={value.id}>
                                                <em>{value.name}</em>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </ListItem>

                    <Divider>

                    </Divider>

                    <Typography variant="overline" component="h2" paddingTop="10px" paddingLeft="10px">
                        Details zur Auswahl
                    </Typography>



                    {
                        createTable()
                    }



                </List>
            </Box>
    );
}

export default TabLai;