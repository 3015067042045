import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ShowChart } from '@mui/icons-material'
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";


const TabObservations = (props) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <List>
                <ListItem key="0" disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <ShowChart/>
                        </ListItemIcon>
                        <ListItemText primary="Beobachtungs-Zeitreihe" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>)
}

export default TabObservations