import React, { useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Sidebar from './components/sidebar/Sidebar';
import MapContainer from './components/map/Map'
import { GraphContainer } from './components/graph_container/graph_container'
import laiService from "./services/laiService/laiService";
import observationService from "./services/observationService/observationService";
import plantService from "./services/plantService/plantService";
import stationService from "./services/stationService/stationService";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const leftDrawerWidth = 260;

export const rightDrawerWidth = 360;

export const nrgIds = [
    74,
    75,
    77,
    78,
    79,
    80,
    81,
    82,
    83,
    84,
    85,
    87,
    88,
]

export const nr_gr = [
    "Rückland der Mecklenburgischen Seenplatte",
    "Mecklenburgische Seenplatte",
    "Nordbrandenburgisches Platten- und Hügelland",
    "Luchland",
    "Ostbrandenburgische Platte",
    "Odertal",
    "Mittelbrandenburgische Platten und Niederungen",
    "Ostbrandenburgisches Heide- und Seengebiet",
    "Spreewald",
    "Lausitzer Becken- und Heideland",
    "Fläming",
    "Elbtalniederung",
    "Elbe- Elster-Land"
]

export const region = [
    "Uckermark",
    "Nordbrandenburgisches Wald- und Seengebiet",
    "Prignitz und Ruppiner Land",
    "Rhin-Havelland",
    "Barnim und Lebus",
    "Odertal",
    "Mittlere Mark",
    "Ostbrandenburgisches Heide- und Seengebiet",
    "Spreewald",
    "Niederlausitz",
    "Fläming",
    "Unteres Havelland",
    "Elbe-Mulde-Tiefland",
]

export const ackerPlants = [
    252, -9999, -9998, 201, 25, 250, 215, 205, 207, 253, 202, 232, 208, 203, 204, 209, 233
]

export const waldPlants = [
    107, 113, 123, 131, 118, 131, 130,
]


export const getPhaseColour = (phaseId) => {
    const phaseIds = [1, 4, 5, 6, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
        24, 25, 26, 27, 28, 31, 32, 35, 36, 60, 62, 65, 67, 9991, 9992, 9993, 9994]
    const colours = ['#FF2D00', '#FF7B00', '#FFD900', '#C0FF00', '#6EFF00', '#00FF85', '#00FFD0', '#00DFFF', '#00A3FF', '#0051FF',
        '#6300FF', '#CB00FF', '#FF00D1', '#FF0070', '#7F0000', '#7F7700', '#247F00', '#007F5E', '#00447F', '#08007F', '#53007F',
        '#7F0068', '#FFF974', '#74FFF2', '#74A8FF', '#A074FF', '#FF74FD', '#FF7491', '#999999', '#393939',
        '#004ab4', '#37b60b', '#d90000', '#dcca02']
    const index = phaseIds.indexOf(phaseId)
    if (index !== -1) {
        return colours[index]
    }
    else return '#000000'
}

export const getNrgName = (nrg_id) => {
    const index = nrgIds.indexOf(nrg_id)
    return nr_gr[index]
}

export const modelParams = [
    { name: 'Keiner' },
    { name: 'Blattflächenindex (LAI)' }
]

export default function App() {
    const [isGraphOpen, setGraphOpen] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [isMapOpen, setMapOpen] = useState(true)
    const [selectedModelParam, setSelectedModelParam] = useState(modelParams[0])
    const [graphHeight, setGraphHeight] = useState(0)
    const [mapHeight, setMapHeight] = useState("1000px")
    const [stations, setStations] = useState([])
    const [detailsViewOpen, setDetailsViewOpen] = useState(false)
    const [selectedStation, setSelectedStation] = useState(null)
    const [allStations, setAllStations] = useState([])
    const [availableStations, setAvailableStations] = useState([])
    const [selectedEfl, setSelectedEfl] = useState(null)
    const [nearbyEfls, setNearbyEfls] = useState(null)
    const [laiSeries, setLaiSeries] = useState([])
    const [rawObservations, setRawObservations] = useState([])
    const [observations, setObservations] = useState([])
    const [startDate, setStartDate] = React.useState('1991-01-01');
    const [endDate, setEndDate] = React.useState('2022-01-01');
    const [availablePlants, setAvailablePlants] = React.useState([])
    const [selectedPlant, setSelectedPlant] = React.useState('')
    const [selectedNrg, setSelectedNrg] = React.useState('')
    const [selectedNrgName, setSelectedNrgName] = React.useState('')
    const [aggregate, setAggregate] = React.useState(false)
    const [observationCounts, setObservationCounts] = React.useState(null)
    const [lod, setLod] = React.useState(0)
    const [dwdChecked, setDwdChecked] = React.useState(true);
    const [iNatChecked, setINatChecked] = React.useState(false);

    const [loadingPlants, setLoadingPlants] = React.useState(false)
    const [loadingLaiSeries, setLoadingLaiSeries] = React.useState(false)
    const [loadingObservations, setLoadingObservations] = React.useState(false)

    useEffect(async () => {
        let data = await stationService.getAllStations(selectedNrg)
        setAllStations(data)
        console.log(data)
    }, [])

    useEffect(async () => {
        if (selectedNrg !== '') {
            let stationsFilteredByNrg = allStations.filter(function(item) { return item.nrg_id === selectedNrg})
            setAvailableStations(stationsFilteredByNrg)
            //setSelectedStation(null)
        }
    }, [selectedNrg])

    useEffect(async () => {
        if (selectedStation !== null && selectedPlant !== '') {
            setLoadingLaiSeries(true)
            console.log(availablePlants)
            console.log(selectedPlant)

            let data
            if (ackerPlants.indexOf(selectedPlant.id) !== -1) {
                console.log("acker")
                data = await laiService.getLaiSeriesAcker(selectedStation._id, 5000, 1510, selectedPlant._id)
            }
            else if (waldPlants.indexOf(selectedPlant.id) !== -1) {
                console.log("non acker")
                data = await laiService.getLaiSeriesNonAcker(selectedStation._id, 5000,1430)
                console.log(data)
            }
            else {
                console.log("nothing")
                data = []
            }
            setLaiSeries(data)
            setLoadingLaiSeries(false)
        }
    }, [selectedStation, selectedPlant])

    useEffect(async () => {
        if (selectedStation !== null && selectedPlant !== '') {
            setLoadingObservations(true)
            const obs = await observationService.getObservationData(selectedStation._id, selectedPlant._id)
            setRawObservations(obs)
            setLoadingObservations(false)
        }
    }, [selectedStation, selectedPlant])

    useEffect(async () => {
        if (selectedNrg !== '' && selectedPlant !== '') {
            setLoadingObservations(true)
            const obs = await observationService.getAggregatedObservationsData(selectedNrg, selectedPlant._id)
            setRawObservations(obs)
            const count = await stationService.getStationObservationCount(selectedNrg, selectedPlant._id)
            setObservationCounts(count)
            setLoadingObservations(false)
        }
    }, [selectedNrg, selectedPlant])

    useEffect(async () => {
        if (selectedStation !== null) {
            setLoadingPlants(true)
            const availablePlants = await plantService.getStationAvailablePlants(selectedStation._id)
            setAvailablePlants(availablePlants)
            if (selectedStation.nrg_id !== selectedNrg) {
                setSelectedNrg('')
            }
            setLoadingPlants(false)
        }
    }, [selectedStation])

    useEffect(async () => {
        if (selectedNrg !== '') {
            const availablePlants = await plantService.getNrgAvailablePlants(selectedNrg)
            setAvailablePlants(availablePlants)
        }
    }, [selectedNrg])

    useEffect(async () => {
        if (selectedNrg === '' && selectedStation === null) {
            const availablePlants = await plantService.getAllAvailablePlants()
            setAvailablePlants(availablePlants)
        }
    }, [])

    useEffect(() => {
        const keyDownHandler = event => {
            console.log('User pressed: ', event.key);

            if (event.key === 'Escape') {
                event.preventDefault();
                setSelectedNrg('')
                setSelectedStation(null)
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    useEffect(async () => {
        console.log(selectedNrg)
        console.log(selectedStation)
        console.log(selectedPlant)
        if (selectedNrg === '' && selectedStation === null && selectedPlant !== '') {
            const count = await stationService.getStationObservationCount(nrgIds, selectedPlant._id)
            console.log(count)
            setObservationCounts(count)
        }
    }, [selectedPlant])


    const handleClearPlant = () => {
        setSelectedPlant('')
        setObservations([])
    }

    const handleClearNrg = () => {
        setSelectedNrg('')
        setSelectedPlant('')
        setObservations([])
    }

    const handleClearStation = () => {
        setSelectedStation(null)
        setSelectedPlant('')
    }

    useEffect(() => {
        if (rawObservations !== []) {
            var arrayCopy = JSON.parse(JSON.stringify(rawObservations))
            var obsFlat = arrayCopy.flat()
            for (let i = 0; i < obsFlat.length; i++) {
                obsFlat = obsFlat.sort(function(a, b) {
                    let keyA = new Date(a.metadata.date)
                    let keyB = new Date(b.metadata.date)
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }
            let formattedObs = []
            if (lod === 0) {
                console.log("formatting obs with lod " + lod)
                for (let j = 0; j < obsFlat.length; j++) {
                    if (obsFlat[j].phase_id !== null) {
                        formattedObs.push(obsFlat[j])
                    }
                }
            }
            else if (lod === 1) {
                console.log("formatting obs with lod " + lod)
                for (let j = 0; j < obsFlat.length; j++) {
                    if (dwdChecked && obsFlat[j].phase_id !== null
                        ||
                        iNatChecked && obsFlat[j].phase_id === null) {
                        formattedObs.push(obsFlat[j])
                        formattedObs[j].phase_id = formattedObs[j].phase_id1
                    }
                }
            }
            console.log(formattedObs)
            setObservations(formattedObs)
        }
    }, [rawObservations, lod, dwdChecked, iNatChecked])

    return (
        <div className="App">
            <Sidebar
                sidebarOpen={sidebarOpen}
                openSidebar={(value) => {
                    setSidebarOpen(value)
                }}
                isMapOpen={isMapOpen}
                setMapOpen={(value) => {
                    setMapOpen(value)
                }}
                handleClearPlant={handleClearPlant}
                handleClearStation={handleClearStation}
                handleClearNrg={handleClearNrg}
                selectedModelParam={selectedModelParam}
                setSelectedModelParam={(value) => setSelectedModelParam(value)}
                startDate={startDate}
                setStartDate={(value) => setStartDate(value)}
                endDate={endDate}
                setEndDate={(value) => setEndDate(value)}
                allStations={allStations}
                availablePlants={availablePlants}
                selectedPlant={selectedPlant}
                setSelectedPlant={setSelectedPlant}
                selectedNrg={selectedNrg}
                selectedNrgName={selectedNrgName}
                setSelectedNrg={(value) => {
                    setSelectedNrg(value)
                    setSelectedStation(null)
                }}
                setSelectedNrgName={(value) => setSelectedNrgName(value)}
                selectedStation={selectedStation}
                aggregate={aggregate}
                setSelectedStation={(value) => {
                    setSelectedStation(value)
                }}
                loadingPlants={loadingPlants}
                setLod={(value) => {setLod(value)}}
                dwdChecked={dwdChecked}
                iNatChecked={iNatChecked}
                setDwdChecked={(value)=>{setDwdChecked(value)}}
                setINatChecked={(value)=>{setINatChecked(value)}}
            />
            {/*<DetailsView
                detailsViewOpen={detailsViewOpen}
                setDetailsViewOpen={(value) => setDetailsViewOpen(value)}
                selectedStation={selectedStation}

                selectedEfl={selectedEfl}
                observations={observations}
                nearbyEfls={nearbyEfls}
                aggregate={aggregate}
                selectedNrg={selectedNrg}
                selectedPlant={selectedPlant}
                availablePlants={availablePlants}
                setSelectedPlant={(value) => setSelectedPlant(value)}
            />*/}
            <GraphContainer
                setEndDate={(value) => setEndDate(value)}
                setStartDate={(value) => setStartDate(value)}
                open={isGraphOpen}
                graphHeight={graphHeight}
                setGraphHeight={(value) => setGraphHeight(value)}
                setMapHeight={(value) => setMapHeight(value)}
                selectedModelParam={selectedModelParam}
                isMapOpen={isMapOpen}
                isSidebarOpen={sidebarOpen}
                isDetailsViewOpen={detailsViewOpen}
                laiSeries={laiSeries}
                observations={observations}
                startDate={startDate}
                endDate={endDate}
                selectedPlant={selectedPlant}
                aggregate={aggregate}
                loadingPlants={loadingPlants}
                loadingLaiSeries={loadingLaiSeries}
                loadingObservations={loadingObservations}
            />
            <MapContainer
                stations={stations}
                allStations={allStations}
                setStations={(value) => {
                    setStations(value)}
                }
                setSelectedStation={(value) => {
                    setSelectedStation(value)
                }}
                setSelectedEfl={(value) => {
                    setSelectedEfl(value)
                }}
                setNearbyEfls={(value) => {
                    setNearbyEfls(value)
                }}
                selectedStation={selectedStation}
                mapHeight={mapHeight}
                aggregate={aggregate}
                setAggregate={(value) =>
                    setAggregate(value)
                }
                selectedNrg={selectedNrg}
                setSelectedNrg={(value) => {
                    setSelectedNrg(value)
                    setSelectedStation(null)
                    }
                }
                setSelectedNrgName={(value) =>
                    setSelectedNrgName(value)
                }
                observationCounts={observationCounts}
                isMapOpen={isMapOpen}
                setMapOpen={(value) =>
                    setMapOpen(value)
                }
            />

        </div>
    );
}