import React, {useRef, useEffect, useState} from "react";
import * as d3 from 'd3'
import _ from 'lodash'
import {getPhaseColour, ackerPlants, waldPlants} from "../../App";

const LaiGraph = (props) => {
    const padding = 80

    const svgRef = useRef()

    const [refIds, setRefIds] = useState([])
    const [labelIds, setLabelIds] = useState([])
    const [isAckerLai, setIsAckerLai] = useState(false)

    const formatTime = d3.utcFormat("%B %d, %Y");

    const formatLaiSeries = (laiSeries) => {
        let start = new Date(props.startDate)
        let end = new Date(props.endDate)
        let data = []
        console.log(props.selectedPlant.id)
        const acker = (ackerPlants.indexOf(props.selectedPlant.id) !== -1)
        console.log(acker)
        for (let i = 0; i < laiSeries.data.length; i++) {
            let currentDate = new Date(laiSeries.start_date)
            currentDate.setDate(currentDate.getDate() + i)
            if (currentDate - start >= 0 && currentDate - end <= 0) {
                if (acker) {
                    data.push({
                        date: currentDate,
                        value: laiSeries.data[i].value,
                        efl_ids: laiSeries.efl_ids[i]
                    })
                }
                else {
                    data.push({
                        date: currentDate,
                        value: laiSeries.data[i].value,
                        efl_ids: ''
                    })
                }
            }
        }
        console.log(data.length)
        return data
    }

    const formatObservations = (observations) => {
        console.log("starting lai series format")
        let parseDate = d3.timeParse("%m-%d-%Y");
        let data = []
        let obsLabels = []
        let start = new Date(props.startDate)
        let end = new Date(props.endDate)

        for (let i = 0; i < observations.length; i++) {
            const date = new Date(observations[i].metadata.date)
            if (date - start >= 0 && date - end <= 0) {
                if (!obsLabels.find(e => e._id === observations[i].phase_id._id)) obsLabels.push(observations[i].phase_id)
                data.push({
                    unparsedDate: (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear(),
                    value: 999,
                    phaseId: observations[i].phase_id.phase_id,
                    _id: observations[i]._id
                })
            }
        }

        data.forEach(d=>{
            d.date = parseDate(d.unparsedDate);
        });

        let formattedData = []
        for (let i = 0; i < data.length; i++) {
            let copiedObs = JSON.parse(JSON.stringify(data[i]))
            formattedData.push([
                copiedObs,
                data[i]
            ])
            formattedData[i][0].date = parseDate(formattedData[i][0].unparsedDate)
            formattedData[i][0].value = 0
        }

        return { formattedData: formattedData, labelsObservations: obsLabels }
    }

    useEffect(()=>{
        //no longer needed!!

        /*
        if (!_.isEmpty(props.plantTimetable) && !_.isEmpty(props.selectedPlant)) {

            const xScale = d3.scaleTime()
                .domain([new Date( props.startDate), new Date(props.endDate)])
                //new Date(chartData[chartData.length - 1].date)])
                /*.domain(d3.extent(chartData, function(d) {
                    return d.date
                }))
                //lowest to highest
                .range([0+padding, props.width-padding])
            d3.selectAll('.plant_timeperiod_rect').remove()
            for (let i = 0; i < props.plantTimetable.length; i++) {
                if (props.plantTimetable[i].plant_id === props.selectedPlant.id) {
                    let startDate = new Date(props.laiSeries.start_date)
                    let endDate = new Date(props.laiSeries.start_date)
                    startDate = startDate.addDays(props.plantTimetable[i].start_date)
                    endDate = endDate.addDays(props.plantTimetable[i].end_date)
                    var left = xScale(startDate);
                    var right = xScale(endDate);
                    var wid = right - left;
                    d3.select(svgRef.current)
                        .append("rect")
                        .attr('class', 'plant_timeperiod_rect')
                        .attr("x", left)
                        .attr("width", wid)
                        .attr("height", props.height)
                        .attr('fill', 'teal')
                        .attr("opacity", 0.2)
                }
            }
        }*/

    }, [props.selectedPlant, props.startDate, props.endDate])

    useEffect(() => {
        if (props.laiSeries.start_date) {
            //console.log(props.height)
            //console.log(props.width)
            let chartData = formatLaiSeries(props.laiSeries, props.startDate)
            //console.log(chartData)
            let observationData = []
            let labelsObservations = []
            if (!_.isEmpty(props.observations)) {
                let data = formatObservations(props.observations, props.laiSeries.start_date)
                observationData = data.formattedData
                labelsObservations = data.labelsObservations
            }

            //console.log(new Date( props.startDate + "-01-01"))
            //console.log(new Date(chartData[chartData.length - 1].date))
            // X SCALE
            const xScale = d3.scaleTime()
                .domain([new Date( props.startDate), new Date(props.endDate)])
                //new Date(chartData[chartData.length - 1].date)])
                /*.domain(d3.extent(chartData, function(d) {
                    return d.date
                }))*/
                //lowest to highest
                .range([0+padding, props.width-padding])

            // Y SCALE
            const maxY = d3.max(chartData, function (d) {
                return d.value
            })
            const yScale = d3.scaleLinear()
                .clamp(true)
                .domain([props.laiMin, props.laiMax])
                //highest to lowest
                .range([props.height-padding, padding])


            // LINES
            //provides coords for each data point that can be used to draw via the d attribute
            //lai data series
            const laiLine = d3.line()
                .x(function(d) { return xScale(d.date) })
                .y(function(d) { return yScale(d.value) })
            //reference lines for obs
            const refLines = d3.line()
                .x(function(d) {return xScale(d.date)})
                .y(function(d) {return yScale(d.value)})

            //DRAW THE LINES
            //add the attribute "d" to the "path" element
            //remember: path element uses d to draw line
            d3.select(svgRef.current)
                .select('#lai')
                .attr('d', (value) => laiLine(chartData))
                .attr('fill', 'none')
                .attr('stroke', 'black')
                .attr('stroke-width', '1.5')

            for (let i = 0; i < refIds.length; i++) {
                let refLine = d3.select('#' + refIds[i])
                refLine.remove()
            }
            let ids = []
            if (!_.isEmpty(observationData)) {
                observationData.forEach((observation) => {
                    if (observation[0].date - new Date(props.startDate) > 0) {
                        console.log("adding obs")
                        d3.select(svgRef.current)
                            .append('path')
                            .attr('class', 'refline')
                            .attr('id', "o" + observation._id)
                            .attr('d', (value) => refLines(observation))
                            .attr('fill', 'none')
                            .attr('stroke-width', '1.5')
                            .attr('stroke', getPhaseColour(observation[0].phaseId))
                        ids.push("o" + observation._id)
                    }
                })
                setRefIds(ids)
            }
            if (!_.isEmpty(labelsObservations)) {
                for (let i = 0; i < labelIds.length; i++) {
                    d3.select(svgRef.current)
                        .selectAll('#l' + i)
                        .remove()
                    d3.select(svgRef.current)
                        .selectAll('#t' + i)
                        .remove()
                }
                let newLabelIds = []
                let padding = 0
                for(let i = 0; i < labelsObservations.length; i++) {
                    let size = 20
                    let xpos = Math.trunc(i / 2)
                    let ypos = i % 2
                    if (i >= 2 && i % 2 === 0) {
                        //calc width of previous elements
                        let t0 = d3.select(svgRef.current).selectAll('#t' + (i - 2))
                        let t1 = d3.select(svgRef.current).selectAll('#t' + (i - 1))
                        let t0box = t0.node().getBBox()
                        let t1box = t1.node().getBBox()
                        padding += Math.max(t0box.width, t1box.width) + size + 20
                    }
                    d3.select(svgRef.current)
                        .append("rect")
                        .attr('id', "l" + i)
                        .attr("x", 100 + padding)
                        .attr("y", 25 + ypos*(size+5)) // 100 is where the first dot appears. 25 is the distance between dots
                        .attr("width", size)
                        .attr("height", size)
                        .style("fill", getPhaseColour(labelsObservations[i].phase_id))
                        .lower()
                    d3.select(svgRef.current)
                        .append("text")
                        .classed('noselect', true)
                        .attr('id', "t" + i)
                        .attr("x", 100  + padding + size + 5)
                        .attr("y", 25 + ypos*(size+5) + (size/2)) // 100 is where the first dot appears. 25 is the distance between dots
                        .style("fill", '#000000')
                        .text(labelsObservations[i].name)
                        .attr("text-anchor", "left")
                        .style("alignment-baseline", "middle")
                        .style("font-size", "12px")
                        .lower()
                    newLabelIds.push(i)
                }

                setLabelIds(newLabelIds)
            }


            //X AND Y AXIS
            //provides coords for each axis
            const xAxis = d3.axisBottom(xScale)
            const yAxis = d3.axisLeft(yScale)

            //DRAW X AND Y AXIS
            d3.select('#xaxis').remove()
            d3.select(svgRef.current)
                .append('g')
                .attr('transform', `translate(0,${props.height-padding})`)
                .attr('id', 'xaxis')
                .call(xAxis
                    //.tickFormat(d3.timeFormat("%d-%m-%g")))
                    //.ticks(10))
                    //.tickFormat(d3.timeFormat("%Y-%m-%d"))
                    //.tickValues(chartData.map(function(d) { return new Date(d.date)}) )
                    .ticks(10))
                .selectAll("text")
                .classed('noselect', true)
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-65)")
                .style("font-size", "12px")
            let xAxisBBox = d3.select(svgRef.current)
                .selectAll('#xaxis')
                .node()
                .getBBox()
            let translateX = d3.select(svgRef.current)
                .selectAll('#xaxis')
                .attr('transform')
            d3.select(svgRef.current)
                .selectAll("#xAxisLabel")
                .remove()
            d3.select(svgRef.current)
                .append("text")
                .classed('noselect', true)
                .attr('id', 'xAxisLabel')
                .attr('transform', translateX)
                .attr('x', xAxisBBox.width)
                .attr('y', xAxisBBox.height + 25)
                .style("fill", '#000000')
                .text("Datum")
                .attr("text-anchor", "left")
                .style("alignment-baseline", "middle")
                .style("font-size", "12px")

            d3.select('#yaxis').remove()
            d3.select(svgRef.current)
                .append('g')
                .attr('transform', `translate(${padding},0)`)
                .attr('id', 'yaxis')
                .call(yAxis)
                .selectAll("text")
                .classed('noselect', true)
            let yAxisBBox = d3.select(svgRef.current)
                .selectAll('#yaxis')
                .node()
                .getBBox()
            let translateY = d3.select(svgRef.current)
                .selectAll('#yaxis')
                .attr('transform')
            console.log(translateY)
            d3.select(svgRef.current)
                .selectAll("#yAxisLabel")
                .remove()
            d3.select(svgRef.current)
                .append("text")
                .classed('noselect', true)
                .attr('id', 'yAxisLabel')
                .attr('transform', 'rotate(-90) translate(-100, 30)')
                .attr('x', 0)
                .attr('y', 0)
                .style("fill", '#000000')
                .text("LAI")
                .classed('noselect', true)
                .attr("text-anchor", "left")
                .style("alignment-baseline", "middle")
                .style("font-size", "12px")

            // TOOLTIP
            const xScale2 = d3.scaleTime()
                .domain([new Date( props.startDate), new Date(props.endDate)])
                //lowest to highest
                .range([0, props.width])
            if (ackerPlants.indexOf(props.selectedPlant.id) !== -1) createTooltipAcker(xScale, chartData, refLines, xScale2)
            else if (waldPlants.indexOf(props.selectedPlant.id) !== -1)createTooltipNonAcker(xScale, chartData, refLines)
        }
        d3.select(svgRef.current)
            .select('#lai')
            .raise()

    }, [props.laiSeries, props.startDate, props.endDate, props.observations, props.height, props.width, props.drawerPxHeight, props.drawerPxWidth, props.laiMin, props.laiMax])

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    function smoothstep(edge0, edge1, value) {
        let x = (value - edge0) / (edge1 - edge0)
        x = Math.max(0, x)
        x = Math.min(1, x)
        return x * x * (3 - 2 * x)
    }

    function createTooltipNonAcker(xScale, chartData, refLines) {
        d3.select(svgRef.current)
            .append("rect")
            .attr('id', 'parent_rect')
            .style("visibility", "hidden")
            .attr("height", 65)
            .attr("width", 100)
            .attr("rx", 5)
            .attr("ry", 5)
            .style("fill", "#9e9e9e")
            .raise()
        d3.select(svgRef.current)
            .append("text")
            .classed('noselect', true)
            .attr('id', 'tooltip')
            .attr('x', 20)
            .attr('y', 20)
            .text("")
            .style("visibility", "hidden")
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
            .style("font-size", "16px")
            .raise()
        d3.select(svgRef.current)
            .append("text")
            .classed('noselect', true)
            .attr('id', 'tooltip_lai')
            .attr('x', 20)
            .attr('y', 20)
            .text("")
            .style("visibility", "hidden")
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
            .style("font-size", "16px")
            .raise()
        d3.select(svgRef.current)
            .append('path')
            .attr('class', 'tooltipline')
            .style("visibility", "hidden")
            .attr('id', "tooltip_refline")
            .attr('fill', 'none')
            .attr('stroke-width', '1.5')
            .attr('stroke', '#000000')
            .raise()
        d3.select(svgRef.current)
            .on("mouseover", function(event){
                const dateCheck =
                    (xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x) - new Date(props.startDate) > 0)
                    &&
                    (new Date(props.endDate) - (xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x)) > 0)
                if (dateCheck && !props.draggerActive) {
                    d3.select('#parent_rect')
                        .style("visibility", "visible")
                    d3.select('#tooltip')
                        .style("visibility", "visible")
                    d3.select('#tooltip_lai')
                        .style("visibility", "visible")
                    d3.select('#tooltip_refline')
                        .style("visibility", "visible")
                }
            })
            .on("mousemove", function(event){
                const dateCheck =
                    (xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x) - new Date(props.startDate) > 0)
                    &&
                    (new Date(props.endDate) - (xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x)) > 0)
                if (!dateCheck || props.draggerActive) {
                    d3.select('#parent_rect')
                        .style("visibility", "hidden")
                    d3.select('#tooltip')
                        .style("visibility", "hidden")
                    d3.select('#tooltip_lai')
                        .style("visibility", "hidden")
                    d3.select('#tooltip_refline')
                        .style("visibility", "hidden")
                }
                else {
                    const date = xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x)
                    const days = Math.round((new Date(props.endDate) - new Date(props.startDate)) / (1000 * 60 * 60 * 24));
                    const index = parseInt(((xScale(date)) / (props.width)) * days)
                    const ratio = smoothstep(0, 1, (index / chartData.length))
                    const offset = ratio * -300
                    if (index >= 0 && index < chartData.length) {
                        d3.select('#parent_rect')
                            .attr("x", (event.pageX-svgRef.current.getBoundingClientRect().x + offset))
                            .attr("y",(svgRef.current.getBoundingClientRect().y - 50))
                            .attr("width", 300)
                        d3.select('#tooltip')
                            .attr("x", event.pageX-svgRef.current.getBoundingClientRect().x + offset + 10)
                            .attr("y",(svgRef.current.getBoundingClientRect().y - 35))
                            .text(
                                "" + formatTime(date)
                            )
                        d3.select('#tooltip_lai')
                            .attr("x", (event.pageX-svgRef.current.getBoundingClientRect().x + offset + 10))
                            .attr("y",(svgRef.current.getBoundingClientRect().y + 5))
                            .text(
                                "" + "LAI Wert: " + Math.round(chartData[index].value * 100) / 100
                            )
                        d3.select('#tooltip_refline')
                            .attr('d', (value) => refLines([
                                {date: xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x), value: 999},
                                {date: xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x), value: 0}
                            ]))
                    }
                }
            })
            .on("mouseout", function(event){
                d3.select('#parent_rect')
                    .style("visibility", "hidden")
                d3.select('#tooltip')
                    .style("visibility", "hidden")
                d3.select('#tooltip_lai')
                    .style("visibility", "hidden")
                d3.select('#tooltip_refline')
                    .style("visibility", "hidden")
            })
    }

    function createTooltipAcker(xScale, chartData, refLines, xScale2) {
        d3.select(svgRef.current)
            .append("rect")
            .attr('id', 'parent_rect')
            .style("visibility", "hidden")
            .attr("height", 65)
            .attr("width", 100)
            .attr("rx", 5)
            .attr("ry", 5)
            .style("fill", "#9e9e9e")
            .raise()
        d3.select(svgRef.current)
            .append("text")
            .classed('noselect', true)
            .attr('id', 'tooltip_efl')
            .attr('x', 20)
            .attr('y', 40)
            .text("")
            .style("visibility", "hidden")
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
            .style("font-size", "16px")
            .raise()
        d3.select(svgRef.current)
            .append("text")
            .classed('noselect', true)
            .attr('id', 'tooltip')
            .attr('x', 20)
            .attr('y', 20)
            .text("")
            .style("visibility", "hidden")
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
            .style("font-size", "16px")
            .raise()
        d3.select(svgRef.current)
            .append("text")
            .classed('noselect', true)
            .attr('id', 'tooltip_lai')
            .attr('x', 20)
            .attr('y', 20)
            .text("")
            .style("visibility", "hidden")
            .attr("text-anchor", "left")
            .style("alignment-baseline", "middle")
            .style("font-size", "16px")
            .raise()
        d3.select(svgRef.current)
            .append('path')
            .attr('class', 'tooltipline')
            .style("visibility", "hidden")
            .attr('id', "tooltip_refline")
            .attr('fill', 'none')
            .attr('stroke-width', '1.5')
            .attr('stroke', '#000000')
            .raise()
        d3.select(svgRef.current)
            .on("mouseover", function(event){
                const dateCheck =
                    (xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x) - new Date(props.startDate) > 0)
                    &&
                    (new Date(props.endDate) - (xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x)) > 0)
                if (dateCheck && !props.draggerActive) {
                    d3.select('#parent_rect')
                        .style("visibility", "visible")
                    d3.select('#tooltip')
                        .style("visibility", "visible")
                    d3.select('#tooltip_efl')
                        .style("visibility", "visible")
                    d3.select('#tooltip_lai')
                        .style("visibility", "visible")
                    d3.select('#tooltip_refline')
                        .style("visibility", "visible")
                }
            })
            .on("mousemove", function(event){
                const dateCheck =
                    (xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x) - new Date(props.startDate) > 0)
                    &&
                    (new Date(props.endDate) - (xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x)) > 0)
                if (!dateCheck || props.draggerActive) {
                    d3.select('#parent_rect')
                        .style("visibility", "hidden")
                    d3.select('#tooltip')
                        .style("visibility", "hidden")
                    d3.select('#tooltip_efl')
                        .style("visibility", "hidden")
                    d3.select('#tooltip_lai')
                        .style("visibility", "hidden")
                    d3.select('#tooltip_refline')
                        .style("visibility", "hidden")
                }
                else {
                    const date = xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x)
                    const days = Math.round((new Date(props.endDate) - new Date(props.startDate)) / (1000 * 60 * 60 * 24));
                    const index = parseInt(((xScale2(date)) / (props.width)) * days)

                    let efl_string_text = "kein aggregierter LAI Wert vorhanden"
                    const ratio = smoothstep(0, 1, (index / chartData.length))
                    const offset = ratio * -300
                    if (index >= 0 && index < chartData.length) {
                        if (chartData[index].efl_ids.length > 1) {
                            efl_string_text = "EFL IDs: "
                            for (let l = 1; l < chartData[index].efl_ids.length; l++) {
                                efl_string_text += chartData[index].efl_ids[l]
                                if (l !== chartData[index].efl_ids.length - 1) efl_string_text += ", "
                            }
                        }
                        d3.select('#parent_rect')
                            .attr("x", (event.pageX-svgRef.current.getBoundingClientRect().x + offset))
                            .attr("y",(svgRef.current.getBoundingClientRect().y - 50))
                            .attr("width", 300)
                        d3.select('#tooltip')
                            .attr("x", event.pageX-svgRef.current.getBoundingClientRect().x + offset + 10)
                            .attr("y",(svgRef.current.getBoundingClientRect().y - 35))
                            .text(
                                "" + formatTime(date)
                            )
                        d3.select('#tooltip_efl')
                            .attr("x", (event.pageX-svgRef.current.getBoundingClientRect().x + offset + 10))
                            .attr("y",(svgRef.current.getBoundingClientRect().y - 15))
                            .text(
                                "" + efl_string_text
                            )
                        d3.select('#tooltip_lai')
                            .attr("x", (event.pageX-svgRef.current.getBoundingClientRect().x + offset + 10))
                            .attr("y",(svgRef.current.getBoundingClientRect().y + 5))
                            .text(
                                "" + "LAI Wert: " + Math.round(chartData[index].value * 100) / 100
                            )
                        d3.select('#tooltip_refline')
                            .attr('d', (value) => refLines([
                                {date: xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x), value: 999},
                                {date: xScale.invert(event.pageX-svgRef.current.getBoundingClientRect().x), value: 0}
                            ]))
                    }
                }
            })
            .on("mouseout", function(event){
                d3.select('#parent_rect')
                    .style("visibility", "hidden")
                d3.select('#tooltip')
                    .style("visibility", "hidden")
                d3.select('#tooltip_efl')
                    .style("visibility", "hidden")
                d3.select('#tooltip_lai')
                    .style("visibility", "hidden")
                d3.select('#tooltip_refline')
                    .style("visibility", "hidden")
            })
    }

    return (<div id="wrapper" style={{position: "relative", width: "100%", height: "100%"}}>
        <svg
            id="chart"
            style={{position: "relative", width: "100%", height: "100%"}}
            ref={svgRef}
            viewBox={`0 0 ${props.width} ${props.height}`}>
            <path id="lai" d="" fill="none" stroke="red" strokeWidth="1"/>
        </svg>
    </div>)

}

export default LaiGraph;

